hr {
  width: 100%;
}

.app {
  height: 100%;
  display: flex;
  flex-wrap: nowrap !important;
  flex-direction: column;
  overflow-x: hidden;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: none !important;
  transition: background-color 5000s ease-in-out 0s;
}

.textfield-min > div > input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.textfield-min > div > input[type='number'] {
  -moz-appearance: textfield;
  appearance: textfield;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #202e9f;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #0e74cc;
  border-radius: 6px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #0e74cc;
}

.main-background {
  background-image: url('./imgs/background.png'),
    linear-gradient(to bottom right, #3c1286, #0d42b2);
  background-repeat: no-repeat;
  background-size: cover;
  flex-grow: 1;
}
