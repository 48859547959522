@import './theme/colors.scss';

* {
  margin: 0;
  padding: 0;
  // line-height: 1;
}

body {
  font-family: Roboto, Helvetica Neue, sans-serif;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}

@import url('./fonts/fonts.css');
